import React from "react"
import InfoPage from "./InfoPage"
import FaqEntry from "./FaqEntry"
import { Link } from "react-router-dom";

const More = ({ children }) => (
    <FaqEntry title="Read More" nobox={true}>{children}</FaqEntry>
);

export default function Privacy() {
    return <InfoPage>
        <h1>Privacy Policy</h1>
        <p>Last Updated: August 12, 2022</p>
        <h2>1. Introduction</h2>
        <p>
            This Privacy Policy describes how Case Parts collects and uses Personal Data about you through the use of our Websites and through email, text, and other electronic communications between you and Case Parts.
        </p>
        <More>
            <p>
                Celsius Joint Venture dba as Case Parts Company (“Case Parts” or “we”) respect your privacy and are committed to protecting it through our compliance with this policy.
            </p><p>
                This Privacy Policy (our “Privacy Policy”) describes the types of information we may collect from you or that you may provide when visiting top-level domain websites (each, a “Website”, collectively the “Websites”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
            </p><p>
                This policy applies to information we collect:
            </p>
            <ul>
                <li>on our Websites;</li>
                <li>in email, text, and other electronic messages between you and our Websites; and</li>
                <li>when you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</li>
            </ul>
            <p>
                It does not apply to information collected by:
            </p>
            <ul>
                <li>us offline or through any other means, including on any other website operated by Case Parts or any third-party; or</li>
                <li>any third party, including through any application or content (including advertising) that may link to or be accessible from or on the Website.
                </li>
            </ul>
            <p>
                Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Websites. By accessing or using these Websites, you agree to this Privacy Policy. This Privacy Policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of these Websites after we make changes is deemed to be acceptance of those changes, so please check this Privacy Policy periodically for updates.
            </p>
        </More>
        <h2>2. Children Under the Age of 18</h2>
        <p>
            Our Websites are not intended for children under the age of 18 and children under the age of 18 are not permitted to use our Websites. We will remove any information about a child under the age of 18 if we become aware of it.
        </p>
        <More>
            <p>
                Our Websites are not intended for children under 18 years of age. No one under age 18 may provide any information to or on the Websites. We do not knowingly collect Personal Data from children under 18. If you are under 18, do not register on the Websites, make any purchases through the Websites, use any of the interactive or public comment features of our Websites or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received Personal Data from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at <a href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</a>.
            </p>
        </More>
        <h2>3. Information We Collect About You and How We Collect It</h2>
        <p>
            We collect different types of information about you, including information that may directly identify you, information that is about you but individually does not personally identify you, and information that we combine with our other users. This includes information that we collect directly from you or through automated collection technologies.
        </p>
        <More>
            <p>
                Generally, we collect several types of information from and about users of our Websites, specifically information:
            </p>
            <ul>
                <li>by which you may be personally identified, such as name, billing, home, work, and/or shipping addresses, work or personal e-mail address, telephone number(s), credit or debit card number (for payment processing only), Federal Tax ID (if you claim that you are tax exempt), your voice (only for audio feedback), or any other identifier by which you may be contacted online or offline (“Personal Data”);</li>
                <li>that is about you but individually does not identify you, such as employer name, title, account number, traffic data, location data, logs, referring/exit pages, date and time of your visit to our Websites, error information, clickstream data, and other communication data and the resources that you access and use on the Websites;</li>
                <li>about your internet connection, the equipment you use to access our Websites and usage details.</li>
            </ul>
            <p>We collect this information:</p>
            <ul>
                <li>directly from you when you provide it to us;</li>
                <li>automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
            </ul>
            <h3>Information You Provide to Us</h3>
            <p>The information we collect on or through our Websites are:</p>
            <ul>
                <li>information that you provide by filling in forms on our Websites. This includes information provided at the time of registering to use our Websites or requesting further services. We may also ask you for information when you enter promotions sponsored by us, and when you report a problem with our Websites;</li>
                <li>your voice recording when you choose to leave us audio feedback;</li>
                <li>records and copies of your correspondence (including email addresses), if you contact us;</li>
                <li>your responses to surveys that we might ask you to complete for research purposes;</li>
                <li>details of transactions you carry out through our Websites and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Websites; and</li>
                <li>your search queries on the Websites.</li>
            </ul>
            <p>The Personal Data we collect from you is required to enter into a contract with Case Parts, for Case Parts to perform under the contract, and to provide you with our products and services. If you refuse to provide such Personal Data or withdraw your consent to our processing of Personal Data, then in some cases we may not be able to enter into the contract or fulfill our obligations to you under it.</p>
            <h3>Information We Collect Through Automatic Data Collection Technologies</h3>
            <p>As you navigate through and interact with our Websites, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, specifically:</p>
            <ul>
                <li>details of your visits to our Websites, specifically traffic data, location data, logs, referring/exit pages, date and time of your visit to our Websites, error information, clickstream data, and other communication data and the resources that you access and use on the Websites; and</li>
                <li>Information about your computer and internet connection, specifically your IP address, operating system, and browser type.</li>
            </ul>
            <p>The information we collect automatically may include Personal Data or we may maintain it or associate it with Personal Data we collect in other ways or receive from third parties. It helps us to improve our Websites and to deliver a better and more personalized service by enabling us to:</p>
            <ul>
                <li>estimate our audience size and usage patterns;</li>
                <li>store information about your preferences, allowing us to customize our Websites according to your individual interests;</li>
                <li>speed up your searches; and</li>
                <li>recognize you when you return to our Websites.</li>
            </ul>
        </More>
        <h2>4. Our Use of Cookies and Other Similar Technologies</h2>
        <p>
            Our Websites use cookies and other similar technologies to provide you with our Websites and their features and to provide you with advertising based on your use of our Websites and your interests. These cookies may be served by us or by third parties we use to help us maintain and provide you with our Websites. You can disable our use of these cookies and other similar technologies by changing your browser settings, however our Website may not function correctly if you select this setting.
        </p>
        <More>
            <p>
                <b>Cookies (or browser cookies)</b>. A cookie is a small file placed on the hard drive of your computer. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. However, if you select this setting you may be unable to access certain parts of our Websites. You can find more information about cookies at <a href="https://www.allaboutcookies.org">https://www.allaboutcookies.org</a>.
            </p>
            <p>
                <b>Web Beacons</b>. Pages of our Websites and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Case Parts, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
            </p>
            <p>
                <b>Tags</b>. Tags are pieces of code used on our Websites that allow us to have a greater understanding about the users of our Websites, such as the users who make a purchase after following an advertisement.
            </p>
            <p>
                <b>First Party and Third Party Cookies</b>. Our Websites use 1st party cookies (set by Case Parts), 3rd party cookies (which are set by third parties), and other similar technologies. First party cookies are set by us (Case Parts). Third party cookies are set by third parties when you access some content or applications, including advertisements, on our websites. These third parties include advertisers, ad networks and servers, content providers, application providers, and social media sites. Unless you set your browser to refuse cookies as described above, these third parties may use cookies (alone or in conjunction with web beacons or other tracking technologies) to collect information about you when you access our Websites. The information they collect may be associated with your Personal Data or they may collect information, including Personal data, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising and other targeted content. We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about advertisement or other targeted content, you should content the responsible provider directly. Below is a list of the different types of cookies that we use on our Websites.
            </p>
            <p>
                <b>Essential Cookies and Similar Technologies</b>: Essential cookies and other similar technologies used by us enable you to navigate the Websites and use their services and features. For example, these types of cookies record your login information so you can access your account and allow us to maintain your shopping cart when you shop on our Websites. Without these essential cookies, the Websites will not perform correctly and we may not be able to provide the Websites to you or provide you with certain services or features of our Websites.
            </p>
            <p>
                <b>Preference Cookies and Similar Technologies</b>: Preference cookies collect information about your choices and preferences and allow us to remember language and other local settings and to customize our Websites accordingly.
            </p>
            <p>
                <b>Social Media Cookies and Similar Technologies</b>Social Media cookies and other similar technologies used by us collect information about your use of social media websites.
            </p>
            <p>
                <b>Analytics Cookies and Similar Technologies</b>: Analytics cookies and other similar technologies used by us collect information about your use of the Websites and enable us to improve the way they work and operates. Specifically, analytics cookies provide us with information regarding what the most frequently visited pages on the Websites are, help us record and debug any issues you may have with the Websites, and show us how effective our advertising is. These analytics cookies do not allow us to see the usage of a single user but does allow us to see overall patterns of usage on the Websites. We do not examine this information for individually identifying information, but we do use this information to understand the traffic on our Websites.
            </p>
            <p>
                <b>Advertising Cookies and Similar Technologies</b>: Advertising and other similar technologies are used to display targeting promotions or advertisements based on your use of the Websites and interests and to manage our use of advertising resources. These cookies collect information about your activities on this and other sites to provide you with interest-based advertising. You can learn more about interest-based advertisements and your opt-out rights and options from members of the Network Advertising Initiative (“NAI”) on its website (www.networkadvertising.org) and from members of the Digital Advertising Alliance on its website (www.aboutads.info). We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can also opt out of receiving targeted ads from members of the NAI on its website.
            </p>
        </More>
        <h2>5. Lawful Basis for Processing Your Personal Data</h2>
        <p>
            We have a lawful basis for our processing of your Personal Data, including processing for our legitimate interests (when balanced against your rights and freedoms), to fulfill our obligations to you under a contract with you, and required by law, and with your consent.
        </p>
        <More>
            <p>If you are in the European Union, the processing of your Personal Data is lawful only if it is permitted under the applicable data protection laws. We have a lawful basis for each of our processing activities (except when an exception applies as described below):</p>
            <ul>
                <li>By using our Websites, you consent to our collection, use, and sharing of your Personal Data as described in this privacy policy. If you do not consent to this privacy policy, please do not use the Websites;</li>
                <li><b>Legitimate Interests</b>. We will process your Personal Data as necessary for our legitimate interests. Our legitimate interests are balanced against your rights and freedoms and we do not process your Personal Data if your rights and freedoms outweigh our legitimate interests. Specifically, our legitimate interests are to: facilitate communication between Case Parts and you; detect and correct bugs and to improve our Websites; safeguard our IT infrastructure and intellectual property; detect and prevent fraud and other financial crime; promote and market our business; check your credit and perform risk assessments; develop our services; and
                </li>
                <li><b>To Fulfill Our Obligations to You under our Contract</b>. We process your Personal Data in order to fulfill our obligations to you pursuant to our Contract with you to deliver our goods and services to you. Specifically, we process your Personal Data to provide you with the products that you order from us. We may also disclose your Personal Data to equipment manufacturers and original equipment manufacturers so that they can assist in fulfilling your order.
                </li>
                <li><b>As Required by Law</b>. We may also process your Personal Data when we are required or permitted to by law; to comply with government inspection, audits, and other valid requests from government or other public authorities; to respond to legal process such as subpoenas; or as necessary for us to protect our interests or otherwise pursue our legal rights and remedies (for instance, when necessary to prevent or detect fraud, attacks against our network, or other criminal and tortious activities), defend litigation, and manage complaints or claims.
                </li>
            </ul>
        </More>
        <h2>6. Special Categories of Information</h2>
        <p>
            We generally do not request you provide and do not process any special categories of Personal Data.
        </p>
        <More>
            <p>
                Case Parts does not ask you to provide, and we do not knowingly collect, any special categories of Personal Data from you, including Personal Data that reveals your racial or ethnic origin, political opinions, religious, philosophical beliefs, or trade unions membership, or the processing of data concerning your health or data concerning your sex life or sexual orientation or history of criminal convictions.
            </p>
        </More>
        <h2>7. Automated Decisions Making</h2>
        <p>
            We generally do not use your Personal Data with any automated decision making processes.
        </p>
        <More>
            <p>
                Case Parts does not use your Personal Data with any automated decision making process.
            </p>
        </More>
        <h2>8. How We Use Your Information</h2>
        <p>
            We use your Personal Data for various purposes described below, including to:
        </p>
        <ul>
            <li>provide our Websites to you;</li>
            <li>provide you with information you request from us;</li>
            <li>enforce our rights arising from contracts;</li>
            <li>notify you about changes; and</li>
            <li>provide you with notices about your account</li>
        </ul>
        <More>
            <p>
                We use information that we collect about you or that you provide to us, including any Personal Data:
            </p>
            <ul>
                <li>to present our Websites and their contents to you;</li>
                <li>to provide you with information, products, or services that you request from us;</li>
                <li>when necessary to fulfill transaction requests, via phone;</li>
                <li>to respond back to you via SMS messaging, if you send us a question via SMS messaging;</li>
                <li>to follow up on your satisfaction with purchases made on the Websites;</li>
                <li>to assist original equipment manufacturers in fulfilling your order and addressing maintenance and support issues;</li>
                <li>to fulfill any other purpose for which you provide it;</li>
                <li>to provide you with notices about your account;</li>
                <li>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
                <li>to notify you about changes to our Websites or any products or services we offer or provide though them;</li>
                <li>to allow you to participate in interactive features on our Websites;</li>
                <li>in any other way we may describe when you provide the information; and</li>
                <li>for any other purpose with your consent.</li>
            </ul>
        </More>
        <h2>9. Disclosure of Your Information</h2>
        <p>We do not share, sell, or otherwise disclose your Personal Data for purposes other than those outlined in this Privacy Policy. We disclose your Personal Data to a few third parties, including:</p>
        <ul>
            <li>our subsidiaries and our affiliates;</li>
            <li>our third-party service providers that we use to support our business;</li>
            <li>to a company we merge, acquire, or that buys us, or in the event of change in structure of our company of any form;</li>
            <li>to comply with our legal obligations;</li>
            <li>to enforce our rights; and</li>
            <li>with your consent.</li>
        </ul>
        <More>
            <p>
                We do not share, sell, or otherwise disclose your Personal Data for purposes other than those outlined in this Privacy Policy. However, we may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
            </p>
            <p>
                We may disclose Personal Data that we collect or you provide as described in this privacy policy
            </p>
            <ul>
                <li>to our subsidiaries and affiliates;</li>
                <li>to contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep Personal Data confidential and use it only for the purposes for which we disclose it to them. Specifically, these entities provide customer service, IT and infrastructure support, payment processing, marketing, and shipping services. Our payment processor’s privacy policies may be found at <a href="https://www.cybersource.com/privacy/">https://www.cybersource.com/privacy/</a></li>
                <li>to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Celsius Joint Venture’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Celsius Joint Venture about one or more of our Websites’ users is among the assets transferred;</li>
                <li>to fulfill the purpose for which you provide it. For example, if you give us an email address to contact us about a question, we will use your email address to respond back to you;</li>
                <li>to original equipment manufacturers to fulfill your order and address maintenance and support issues;</li>
                <li>for any other purpose disclosed by us when you provide the information; and</li>
                <li>with your consent.</li>
            </ul>
            <p>We may also disclose your Personal Data:</p>
            <ul>
                <li>to comply with any court order, law, or legal process, including tor respond to any government or regulatory request;</li>
                <li>to enforce or apply the Terms and Conditions on our Websites and other agreements, including for billing and collection purposes; and</li>
                <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Celsius Joint Venture, our affiliates, subsidiaries, customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
            </ul>
        </More>
        <h2>10. Choices About How We Use and Disclose Your Information</h2>
        <p>
            We offer you choices on how you can opt out of our use of tracking technology, disclosure of your Personal Data for third-parties to advertise to you, our advertising to you, and other targeted advertising.
        </p>
        <More>
            <p>We do not control the collection and use of your information collected by third parties described above in Disclosure of Your Information. When possible, these organizations are under contractual obligations to use this data only for providing the services to us and to maintain this information strictly confidential. These third parties may, however, aggregate the information they collect with information from their other customers for their own purposes.</p>
            <p>In addition, we strive to provide you with choices regarding the Personal Data you provide to us. We have created mechanisms to provide you with control over your Personal Data:</p>
            <ul>
                <li><b>Promotional Offers from Case Parts</b>. We may use your contact information to promote our own or third parties’ products and services. If you wish to opt-out of such use, you can check the relevant box located on the form on which we collect your Personal Data or otherwise seek such consent. If you wish to change your choice, you may do so at any time by logging into the Websites and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <a href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</a>. If we have sent you a promotional email, you may click the unsubscribe link at the bottom of the email to be omitted from future email distributions. This opt out does not apply to information provided to Case Parts as a result of a product purchase, warranty registration, product service experience or other transactions.
                </li>
                <li><b>Targeted Advertising</b>. We may use information that we collect or you provide to deliver advertisements according to our and our advertisers’ target-audience preferences. If you wish to opt-out of such use, you can check the relevant box located on the form on which we collect your Personal Data or otherwise seek such consent. If you wish to change your choice, you may do so at any time by logging into the Websites and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <a href="mailto:customerservice@caseparts.com">customerservice@caseparts.com</a>. For this opt-out to function, you must have your browser set to accept browser cookies.
                </li>
            </ul>
        </More>
        <h2>11. Your Rights Regarding Your Information and Accessing and Correcting Your Information</h2>
        <p>
            You may have certain rights under applicable data protection laws, including the right to access and update your Personal Data, restrict how it is used, transfer certain Personal Data to another controller, withdraw your consent at any time, and the right to have us erase certain Personal Data about you. You also have the right to complain to a supervisory authority about our processing of your Personal Data.
        </p>
        <More>
            <p>
                Applicable data protection laws may provide you with certain rights with regards to our processing of your Personal Data.
            </p>
            <ul>
                <li><b>Access and Update</b>. You can review and change your Personal Data by logging into the Websites and visiting the “My Account” page. You may also notify us through the contact information below or through our Websites’ live chat features between 7 AM and 7 PM PT of any changes or errors in any Personal Data we have about you to ensure that it is complete, accurate, and as current as possible. We may also not be able to accommodate your request if we believe it would violate any law or legal requirement or cause the information to be incorrect.</li>
                <li>You have the right to restrict our processing of your Personal Data under certain circumstances. In particular, you can request we restrict our use of it if you contest its accuracy, if the processing of your Personal Data is determined to be unlawful, or if we no longer need your Personal Data for processing, but we have retained it as permitted by law.</li>
                <li>To the extent the Personal Data you provide Case Parts is processed based on your consent, you have the right to request that we provide you a copy of, or access to, all or part of such Personal Data in structured, commonly used and machine-readable format. You also have the right to request that we transmit this Personal Data to another controller, when technically feasible.</li>
                <li><b>Withdrawal of Consent</b>. To the extent that our processing of your Personal Data is based on your consent, you may withdraw your consent at any time by closing your account. Withdrawing your consent will not, however, affect the lawfulness of the processing based on your consent before its withdrawal, and will not affect the lawfulness of our continued processing that is based on any other lawful basis for processing your Personal Data.</li>
                <li><b>Right to be Forgotten</b>. You have the right to request that we delete all of your Personal Data. We cannot delete your Personal Data except by also deleting your user account, and we will only delete your account when we no longer have a lawful basis for processing your Personal Data or after a final determination that your Personal Data was unlawfully processed. We may not accommodate a request to erase information if we believe the deletion would violate any law or legal requirement or cause the information to be incorrect. In all other cases, we will retain your Personal Data as set forth in this policy. In addition, we cannot completely delete your Personal Data as some data may rest in previous backups. These will be retained for the periods set forth in our disaster recovery policies.</li>
                <li>You have the right to lodge a complaint with the applicable supervisory authority in the country you live in, the country you work in, or the country where you believe your rights under applicable data protection laws have been violated. However, before doing so, we request that you contact us directly in order to give us an opportunity to work directly with you to resolve any concerns about your privacy.</li>
                <li><b>How You May Exercise Your Rights</b>. You may exercise any of the above rights by contacting us through any of the methods below and through the live chat feature of our Websites. If you contact us to exercise any of the foregoing rights, we may ask you for additional information to verify your identity. We reserve the right to limit or deny your request if you have failed to provide sufficient information to verify your identity or to satisfy our legal and business requirements.</li>
            </ul>
        </More>
        <h2>12. Do Not Track Signals</h2>
        <p>
            We may use automated data collection technologies to track you across websites. We currently do not honor do-not-track signals that may be sent by some browsers.
        </p>
        <More>
            <p>
                We also may use automated data collection technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Some web browsers permit you to broadcast a signal to websites and online services indicating a preference that they “do not track” your online activities. At this time, we do not honor such signals and we do not modify what information we collect or how we use that information based upon whether such signal is broadcast or received.
            </p>
        </More>
        <h2>13. Data Security</h2>
        <p>
            Information transmitted over the Internet is not completely secure, but we do our best to protect your Personal Data. You can help protect your Personal Data and other information by keeping your password to our Websites confidential.
        </p>
        <More>
            <p>
                We have implemented measures designed to secure your Personal Data from accidental loss and from unauthorized access, use, alteration, and disclosure. Our payment processors are contractually obligated to encrypt any payment transactions using SSL technology in order to keep your payment information safe. The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Websites, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted to our Websites. Any transmission of Personal Data is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Websites.
            </p>
        </More>
        <h2>14. Consent to Processing of Personal Data In Other Countries Outside the European Economic Area</h2>
        <p>
            We may process your Personal Data outside of your home country, including to the United States. We only do this when we are legally permitted to do so and when we have appropriate safeguards in place to protect your Personal Data.
        </p>
        <More>
            <p>
                In order to provide our Websites, products, and services to you, we may send and store your Personal Data outside of your home country, including to the United States. Accordingly, your Personal Data may be transferred outside the country where you reside or are located, including to countries that may not or do not provide an adequate level of protection for your Personal Data.
            </p>
            <p>
                Your Personal Data is transferred by Case Parts to another country only if it is required or permitted under applicable data protection law and provided that there are appropriate safeguards in place to protect your Personal Data. By using our Websites, you represent that you have read and understood the above and hereby consent to the storage and processing of Personal Data that you provide directly to us on our Websites.
            </p>
        </More>
        <h2>15. Data Retention Periods</h2>
        <p>
            We retain your Personal Data for as long as you keep your account open. In some instances, we may keep it after you close your account, for example we may keep it:
        </p>
        <ul>
            <li>on our backup and disaster recovery systems;</li>
            <li>for as long as necessary to protect our legal interests; and</li>
            <li>and to comply with other legal requirements.</li>
        </ul>
        <More>
            <p>
                Case Parts will retain your personal data for the entire time that you keep your account open. After you close your account, we may retain your Personal Data:
            </p>
            <ul>
                <li>for as long as necessary to comply with any legal requirement;</li>
                <li>on our backup and disaster recovery systems in accordance with our backup and disaster recovery policies and procedures;</li>
                <li>for as long as necessary to protect our legal interests or otherwise pursue our legal rights and remedies; and</li>
                <li>for data that has been aggregated or otherwise rendered anonymous in such a manner that you are no longer identifiable, indefinitely.</li>
            </ul>
        </More>
        <h2>16. Changes To Our Privacy Policy</h2>
        <p>
            We will post any changes to our Privacy Policy on our Websites. If we make material changes to our Privacy Policy, we may notify you of such changes through your contact information and invite you to review (and accept, if necessary) the changes.
        </p>
        <More>
            <p>
                We may change this Privacy Policy at any time. It is our policy to post any changes we make to our Privacy Policy on this page with a notice that the Privacy Policy has been updated on the Websites’ home page. If we make material changes to how we treat our users’ Personal Data, we will notify you by email to the email address specified in your account and/or through a notice on the Websites’ home page. The date this Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Websites and this Privacy Policy to check for any changes.
            </p>
        </More>
        <h2>17. Contact Information</h2>
        <p>You may contact our Data Protection Officer through the <Link to="/contact">Contact Us</Link> page on our Websites.</p>
        <More>
            <p>
                If you have any questions, concerns, complaints or suggestions regarding our Privacy Policy, have any requests related to your Personal Data pursuant to applicable laws, or otherwise need to contact us, you may contact us through the <Link to="/contact">Contact Us</Link> page on our Websites.
            </p>
        </More>
    </InfoPage>
}