import React, { useState, useContext, useEffect, useRef } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import "../styling/styles.css";
import SearchBox from "./SearchBox";
import AppSettings from "../../AppSettings";
import Popup from "reactjs-popup";
import Legal from "../pages/Legal";
import { Link } from "react-router-dom";
import { FindEmployee } from "../../requests/data/GetStaffInfo";
import Burger from "./Burger";
import CancelImpersonate from "./CancelImpersonate";
import NationalAccountId from "./NationalAccountId";

const Wrapper = styled.div`
  width: 100%;
  height: 62px;
  background-color: var(--dark-blue);
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  z-index: 100;
  position: relative;
`;
const LogoBackground = styled.img`
  left: 0px;
  top: -2px;
  position: absolute;
  width: 305px;
  height: 65px;
`;
const Logo = styled.img`
  left: 16px;
  top: 6px;
  position: absolute;
  width: 225px;
  height: 50px;
  object-fit: contain;
  cursor: pointer;
`;
const IconWrapper = styled.div`
  margin-top: 20px;
  float: right;
`;
const Icons = styled.img`
  float: right;
  margin: 0px 12px 20px 12px;
  cursor: pointer;
`;
const Overlay = styled.div`
  background-color: var(--reddish-orange);
  border-color: white;
  margin: 0;
  padding: 0;
  color: white;
  border-radius: 50%;
  border-width: 5px;
  height: 20px;
  width: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  float: right;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  position: relative;
  left: -32px;
  top: -10px;
`;
const SearchWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 304px;
  width: 500px;
  height: 38px;
`;
const SearchLabel = styled.div`
  color: #9dc1d2;
  margin-top: 22px;
  margin-right: 8px;
  float: left;
`;
const Search = styled(SearchBox)`
  padding-top: 11px;
  border-radius: 2px;
  z-index: 2; // needed to overlay Expando
`;
const MenuItem = styled(Link)`
  margin-left: 0px;
  margin-right: 0px;
  color: white;
  cursor: pointer;
`;
const ExternalMenuItem = styled.a`
  margin-left: 0px;
  margin-right: 0px;
  color: white;
`;
const BurgerWrapper = styled.div`
margin-top: 15px;
height: 50px;
width: 50px;
float: right;
padding: 0px;
margin-left: 0px;
margin-right: 12px;
cursor: pointer;
`;
const Account = styled.div`
  margin-top: 4px;
  float: right;
  padding: 0px;
  margin-left: 0px;
  margin-right: 4px;
  color: white;
`;
const AccountLabel = styled.span`
  font-size: 75%;
  color: #9dc1d2;
`;
const Label = styled.div`
  position: absolute;
  top: 20px;
  left: 340px;
  height: 19px;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: regular;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
`;

// doc: https://react-select.com/styles
const customStyles = {
  input: (provided, state) => ({
    ...provided,
    color: "black",
    fontSize: "18px"
  }),
  menuList: (provided, state) => ({
    ...provided,
    color: "black",
    backgroundColor: "var(--pale-grey)"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "var(--CP-blue)"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: "var(--CP-blue)"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "var(--battleship-grey)"
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: "var(--battleship-grey)"
  }),
  control: (provided, state) => ({
    ...provided,
    color: "var(--CP-blue)",
    border: "none",
    borderRadius: "2px",
    backgroundColor: "var(--pale-grey)"
  }),
  menu: (provided, state) => ({
    ...provided,
    marginLeft: "0px",
    color: "red"
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: "#e3f0fd",
    border: "none",
    textAlign: "left",
    color: "var(--black)",
    fontStyle: "italic"
  })
};

const LegalPopup = () => (
  <Popup
    trigger={<ExternalMenuItem>Legal</ExternalMenuItem>}
    modal
    closeOnDocumentClick
    contentStyle={{ width: "570px" }}
  >
    {close => <Legal close={close} />}
  </Popup>
);

export default function MainNav(props) {
  const context = useContext(AppContext);
  const { initialized, cartParts, logout, claims, impersonate, token } = context;
  const count = cartParts.length;
  const employee = FindEmployee(claims.Employee ? claims.Employee.UserName : "");
  const ready = initialized && claims.UserType;

  const cancelImpersonate = () => {
    impersonate(token)
      .catch(err => console.log(err.message))
  }
  // In react, we need a ref to track state in an event listener
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const [menuOpen, _setMenuOpen] = useState(false);
  const menuOpenRef = useRef(menuOpen);
  const setMenuOpen = flag => {
    menuOpenRef.current = flag;
    _setMenuOpen(flag);
  }

  // Automatically close the menu if the user clicks outside the menu
  // https://www.w3docs.com/snippets/javascript/how-to-detect-a-click-outside-an-element.html
  useEffect(() => {
    document.addEventListener("click", (evt) => {
      // We don't need to do anything if the menu isn't open
      if (!menuOpenRef.current) return;

      let target = evt.target; // clicked element      
      const menu = document.getElementById("menu");
      const burger = document.getElementById("burger");

      do {
        if (target === menu || target === burger) {
          // Ignore clicks on the menu or burger
          return;
        }
        // Go up the DOM
        target = target.parentNode;
      } while (target);
      // Clicks elsewhere trigger the menu to close
      setMenuOpen(false);
    });
  }, [])

  const LocalMenuItem = ({ to, children }) =>
    <MenuItem to={to} onClick={() => setMenuOpen(false)}>
      <li className="selectable">{children}</li>
    </MenuItem>

  const AccountInfo = () => {
    if (claims.UserType === "Guest") {
      return ""
    }

    const custName = claims.Customer
      ? <>{claims.Customer.UserName.substr(0, claims.Customer.UserName.indexOf('@'))}</>
      : ""

      
    return <a href={AppSettings.AccountPage} className="header__account-container">
      <Account >
        <AccountLabel>
          {(claims.Customer && claims.Customer.CustId) ? "My Account"
            : claims.Customer ? "User"
              : claims.UserType === "Employee" ? "Employee"
                : ""
          }
        </AccountLabel>
        <br />
        {(claims.Customer && claims.Customer.CustId) ? <>{custName}<br /><b>{claims.Customer.CustId}</b></>
          : claims.Customer ? custName
            : claims.UserType === "Employee" ? <>{employee.shortname}</>
              : ""
        }
        <CancelImpersonate />
      </Account>
      <NationalAccountId account={claims.Customer} size={'38px'} />
    </a>
  }

  return (
    <>
      <div id="menu" className={`menu ${menuOpen ? "show" : "hide"}`}>
        <ul>
          {/* {window.location.pathname !== "/" && <LocalMenuItem to="/">Shop</LocalMenuItem>} */}
          <LocalMenuItem to="/">Shop</LocalMenuItem>
          {claims.Customer && (<>
            <ExternalMenuItem href={`${AppSettings.OrderLinkClient}/orders`}>
              <li className="selectable">Orders &amp; Quotes</li>
            </ExternalMenuItem>
            <ExternalMenuItem href={AppSettings.AccountPage}>
              <li className="selectable">My Account</li>
            </ExternalMenuItem>
            <li><hr /></li>
          </>)}
          <LocalMenuItem to="/custom/">Custom Parts</LocalMenuItem>
          <LocalMenuItem to="/faq">Frequently Asked Questions</LocalMenuItem>
          <LocalMenuItem to="/about">About Case Parts</LocalMenuItem>
          <LocalMenuItem to="/contact">Contact Us</LocalMenuItem>
          {claims.UserType === "Employee" && (
            <>
              <li><hr /></li>
              <LocalMenuItem to="/dash">Dashboard</LocalMenuItem>
              {!claims.Customer &&
                <ExternalMenuItem href={`${AppSettings.OrderLinkClient}?returnUrl=${window.location.href}`}>
                  <li className="selectable">View As Customer</li>
                </ExternalMenuItem>
              }
              {claims.Customer &&
                <MenuItem to="/" onClick={() => { cancelImpersonate(); setMenuOpen(false) }}>
                  <li className="selectable">View As Me</li>
                </MenuItem>
              }
              <ExternalMenuItem href={`${AppSettings.OrderLinkClient}/cpc/users`}>
                <li className="selectable">Customer Lookup</li>
              </ExternalMenuItem>
              <LocalMenuItem to="/orders">Orders Chart</LocalMenuItem>
              <ExternalMenuItem href={AppSettings.ReportClient}>
                <li className="selectable">Other Reports</li>
              </ExternalMenuItem>
              <ExternalMenuItem href={`${AppSettings.IntranetPage}?returnUrl=${window.location.href}`}>
                <li className="selectable">Old Intranet</li>
              </ExternalMenuItem>
            </>
          )}
          {claims.UserType !== 'Guest' && (
            <>
              <li><hr /></li>
              <MenuItem to="/" onClick={() => { logout(); setMenuOpen(false) }}>
                <li className="selectable">Sign Out</li>
              </MenuItem>
            </>
          )}
        </ul>
      </div>
      <Wrapper>
        <LogoBackground src="/images/heading-background.svg" />
        <LegalPopup />
        <Link to="/">
          <Logo src="/images/logo-cp.svg" />
        </Link>
        {!ready && <Label>Loading...</Label>}
        {ready && (
          <>
            <BurgerWrapper><Burger isOpen={menuOpen} toggle={() => setMenuOpen(!menuOpen)} /></BurgerWrapper>
            <AccountInfo />
            <IconWrapper>
              {claims.UserType === "Guest" && (
                <>
                  <Link to={`/Login?returnUrl=${props.location.pathname}`}>
                    <span style={{ color: "white" }}>Sign in</span>
                  </Link>&nbsp;&nbsp;
                  <ExternalMenuItem href={`${AppSettings.BenefitsPage}?returnURL=${window.location.href}`}>
                    Register
                  </ExternalMenuItem>
                </>
              )}
              <Link to={"/cart"}>
                {count > 0 && <Overlay>{count}</Overlay>}
                <Icons src="/images/icon-cart.svg" height="24" width="24" />
              </Link>
            </IconWrapper>
            <SearchWrapper>
              <SearchLabel>Search:</SearchLabel>
              <Search {...props} styles={customStyles} />
            </SearchWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
}
