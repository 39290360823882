import React from "react";
import styled from "styled-components";
import "../styling/styles.css";
import "../../customParts/customParts.css";
import ResearchForm from "../checkout/ResearchForm";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomItems from "../../customParts/widgets/CustomItems";
import InjectHtml from "../components/InjectHtml";
import HolidayMessage from "../components/HolidayMessage"
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
`;
const Banner = styled.div`
  padding 0px 0px 0px 100px;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #ddecf4, #ffffff);
`;
const FloatLeft = styled.div`
  float: left;
  margin-right: 70px;
`;
const Section = styled.div`
  width: 850px;
  margin-left: 20px;
  padding: 20px;
  float: left;
  text-align: center;
`;
const Headline = styled.h1`
  width: 420px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  float: left;
  margin-top: 60px;
`;
const BodyText = styled.p`
  width: 420px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--black);
`;
const Offices = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: var(--greyish-brown);
  padding-top: 8px;
  width: 100%;
`;
const Office = styled.div`
  width: 250px;
  margin: 10px 20px 20px 20px;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 8px;
  vertical-align: text-bottom;
  color: #f38c58; // lighter version of var(--reddish-orange);
`;
const OfficeName = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: #ffffff;
  text-align: center;
  margin: 0px 0px 12px 0px;
`;
const OfficeAddress = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 6px 12px;
  line-height: 1.5;
`;
const OfficeInfo = ({name, map, addr1, addr2}) => (
  <Popup
    trigger={
      <OfficeName>
          {name}<Icon icon={["fas", "location-dot"]} />
      </OfficeName>}
    on="hover"
    mouseLeaveDelay={500}
    mouseEnterDelay={100}
    offsetX={0}
    contentStyle={{ width: "250px", borderRadius: "8px", backgroundColor: "#f5f9fb" }}
  >
    {close => (
      <OfficeAddress>
        <b>Case Parts - {name}</b><br/>{addr1}<br/>{addr2}<br/>
        <a href={map} target="_blank" rel="noopener noreferrer">Directions</a>
      </OfficeAddress>
    )

    }
  </Popup>

);

const OfficePhone = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #ffffff;
  text-align: center;
  margin: 4px 0px;
`;
const OfficeDetails = styled.div`
  opacity: 0.75;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: #ffffff;
`;
const OfficeOutage = styled.div`
  opacity: 1;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: #ff0000;
  background-color: #ffffff;
  margin-top: 8px;
  padding: 2px;
`;
const OfficeFax = styled(OfficeDetails)`
  margin-top: 2px;
  margin-bottom: 4px;
`;
const SectionHeading = styled.div`
  width: 850px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
`;
const RequiredFieldNote = styled.p`
  margin: 0px;
  padding: 0px;
  text-align: center;
  margin: 6px 0px 16px 0px;
`;
const WhiteA = styled.a`
  color: white;
`;
const Divider = styled.hr`
  border-top: 1px solid #c0c0c0;
  margin-top: 8px;
  margin-bottom: 0px;
`;

export default function Home() {
  return (
    <Wrapper>
      <Helmet>
        <title>Case Parts Commercial Refrigeration Parts Specialists</title>
        <meta
          name="description"
          content="Case Parts Commercial Refrigeration Parts Specialists including Latches, Hinges, Gaskets, Heaters, Pans, Walk-In Equipment, Electrical, Thermometers, Alarms, Shelving"
        />
      </Helmet>
      <Banner>
        <FloatLeft>
          <Headline>Speak directly to one of our Refrigeration Parts Experts</Headline>
          <BodyText>
            We have <b>3 locations</b> nationwide to get you your parts <b>faster</b> and <b>cheaper</b>. Find parts
            through our online catalog or contact an expert by <b />
            <b>phone</b>, <b>chat</b> or{" "}
            <b>
              <Link to="/contact">email</Link>
            </b>
            . Our Customer Service Reps have an average of over 12 years experience helping customers. They know what
            questions to ask and where to go to solve your problems!
          </BodyText>
        </FloatLeft>
        <img src="/images/home-parts.png" alt="" style={{ display: "block" }} />
      </Banner>
      <HolidayMessage /* date="1/2/2024" */ /> 
      <Offices>
        <Office>
          <OfficeInfo name="Los Angeles" map="https://goo.gl/maps/PzF4o1beyyTanKVk6" addr1="877 Monterey Pass Road" addr2="Monterey Park, CA 91754" />
          <OfficePhone>
            <WhiteA href="tel:+18004210271">(800) 421-0271</WhiteA>
          </OfficePhone>
          <OfficeFax>
            <WhiteA href="tel:+18009722441">Fax: 800-972-2441</WhiteA>
          </OfficeFax>
          <OfficeDetails>Monday - Friday 7:30AM - 5:00PM PT</OfficeDetails>
          <InjectHtml url="/alerts/mpk.html"><OfficeOutage /></InjectHtml>
        </Office>
        <Office>
          <OfficeInfo name="St. Louis" map="https://goo.gl/maps/JgBTWQ8LyaXR2UrS9" addr1="3218 Rider Trail South" addr2="Earth City, MO 63045" />
          <OfficePhone>
            <WhiteA href="tel:+18004239337">(800) 423-9337</WhiteA>
          </OfficePhone>
          <OfficeFax>
            <WhiteA href="tel:+18002623974">Fax: 800-262-3974</WhiteA>
          </OfficeFax>
          <OfficeDetails>Monday - Friday 7:00AM - 4:30PM CT</OfficeDetails>
          <InjectHtml url="/alerts/stl.html"><OfficeOutage /></InjectHtml>
        </Office>
        <Office>
          <OfficeInfo name="Seattle" map="https://goo.gl/maps/BhvC7NY94vXQG8uW8" addr1="25315 74th Ave South, Suite 101" addr2="Kent, WA 98032" />
          <OfficePhone>
            <WhiteA href="tel:+18007153416">(800) 715-3416</WhiteA>
          </OfficePhone>
          <OfficeFax>
            <WhiteA href="tel:+18007141257">Fax: 800-714-1257</WhiteA>
          </OfficeFax>
          <OfficeDetails>Monday - Friday 7:30AM - 5:00PM PT</OfficeDetails>
          <InjectHtml url="/alerts/sea.html"><OfficeOutage /></InjectHtml>
        </Office>
      </Offices>
      <Section>
        <SectionHeading>
          Configure a Custom-Made Part&nbsp;&nbsp;
          <span className="smaller">
            <i>
              <Link to="/custom">Learn more</Link>
            </i>
          </span>
        </SectionHeading>
        <p>We also are very experienced with fabricating commercial refrigeration parts to your exact needs. </p>
        <CustomItems />
        <Divider />
      </Section>
      <Section>
        <SectionHeading>Not finding what you need? Let us help!</SectionHeading>
        <p>
          We are experts at researching and sourcing refrigeration parts.
          <br />
          Every year, we deliver thousands of orders for parts not included in our extensive catalog.
          <br />
          Just fill in this form and we'll find the right part at a competitive price.
        </p>
        <RequiredFieldNote className="formnote">
          <strong>*</strong> Indicates a required field
        </RequiredFieldNote>
        <ResearchForm />
      </Section>
    </Wrapper>
  );
}
