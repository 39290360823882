import React from "react";
import styled from "styled-components";
import { Field, ErrorMessage } from "formik";
import Popup from "reactjs-popup";
import getStateCode from "../../requests/hooks/getStateCode";

const Extra = styled.div`
  float: right;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  margin-right: 0px;
`;
const Link = styled.a`
  margin-left: 20px;
`;
const KnownAddress = styled.div`
  padding: 8px;
  padding-left: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 370px;
  border: solid 1px #c6c6c6;
  background-color: #e0e0e0;
`;
const ClickableAddress = styled(KnownAddress)`
  cursor: pointer;
  &:hover {
    color: black;
    background-color: var(--highlight-orange);
  }
`;
const Name = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
`;
const PopupWrapper = styled.div`
  margin: 0px;
  padding: 16px 16px 0px 16px;
  max-height: 80vh;
  overflow-y: scroll;
`;

const FormField = ({ name, required, caption, type, component, placeholder, errors, touched, extra, tabIndex }) => (
  <>
    {extra}
    <label htmlFor={name}>
      {caption}
      {required && <strong>*</strong>}
    </label>
    <ErrorMessage name={name} className="error" component="div" />
    <Field
      component={component || "input"}
      type={type || "text"}
      name={name}
      placeholder={placeholder}
      className={errors[name] && touched[name] ? "text-input error" : "text-input"}
      tabIndex={tabIndex}
    />
  </>
);

function Address({ Key, Caption, Street, City, State, Zipcode, Country, onClick }) {
  return (
    <KnownAddress key={Key} onClick={onClick}>
      <Name>{Caption}</Name>
      <br />
      {Street}
      <br />
      {City}, {State} {Zipcode}
      {!!Country && (
        <>
          <br />
          {Country}
        </>
      )}
    </KnownAddress>
  );
}
function ClickableAddr({ Key, Caption, Street, City, State, Zipcode, Country, onClick }) {
  return (
    <ClickableAddress key={Key} onClick={onClick}>
      <Name>{Caption}</Name>
      <br />
      {Street}
      <br />
      {City}, {State} {Zipcode}
      {!!Country && (
        <>
          <br />
          {Country}
        </>
      )}
    </ClickableAddress>
  );
}

function getAddress(addressIndex, addresses) {
  if (addressIndex > 0 && addresses && addressIndex <= addresses.length) {
    const address = addresses[addressIndex - 1];
    return <Address {...address} />;
  }
  return <KnownAddress>Loading...</KnownAddress>;
}

function State({ values, touched }) {
  const stateCode = getStateCode(values.zipcode);
  const state = getStateCode(values.zipcode, true);
  var className = "readonlytext";
  if (touched.zipcode && !stateCode) {
    className += " error";
  }
  return (
    <>
      <label>
        State<strong>*</strong> <i style={{ fontWeight: "normal", fontSize: "75%" }}>(implied by zipcode)</i>
      </label>
      <div className={className}>{state ? state : "Enter a valid US zipcode below"}</div>
    </>
  );
}

const ShippingAddress = ({
  values,
  errors,
  touched,
  addresses,
  addressIndex,
  setAddressIndex,
  setFieldValue,
  requestRefresh,
  tabIndex
}) => {
  const extra = (
    <Extra>
      {addressIndex > 0 && (
        <Link
          onClick={() => {
            const addr = addresses[addressIndex - 1];
            setFieldValue("recipient", addr.Caption);
            setFieldValue("address", addr.Street);
            setFieldValue("city", addr.City);
            setFieldValue("state", addr.State);
            setFieldValue("zipcode", addr.Zipcode);
            setAddressIndex(0);
            requestRefresh();
          }}
        >
          Edit address
        </Link>
      )}
      {addresses && addresses.length > 0 && (
        <>
          <Popup
            trigger={<Link>Select from list</Link>}
            modal
            closeOnDocumentClick={true}
            closeOnEscape
            contentStyle={{ width: "440px", backgroundColor: "#ffffff" }}
          >
            {close => (
              <PopupWrapper>
                <h2>Select desired shipping address</h2>
                {addresses.map((address, index) => (
                  <ClickableAddr
                    key={index}
                    {...address}
                    onClick={() => {
                      const addr = addresses[index];
                      setFieldValue("recipient", addr.Caption);
                      setFieldValue("address", addr.Street);
                      setFieldValue("city", addr.City);
                      setFieldValue("state", addr.State);
                      setFieldValue("zipcode", addr.Zipcode);
                      setAddressIndex(index + 1);
                      requestRefresh();
                      close();
                    }}
                  />
                ))}
              </PopupWrapper>
            )}
          </Popup>
        </>
      )}
    </Extra>
  );
  return (
    <>
      {addressIndex > 0 && (
        <>
          {extra}
          <label htmlFor="address">Shipping Address</label>
          {/* <Address {...addresses[addressIndex - 1]} /> */}
          {getAddress(addressIndex, addresses)}
        </>
      )}
      {addressIndex === 0 && (
        <div>
          <FormField
            name="recipient"
            required
            caption="Recipient"
            placeholder="Enter recipient for shipping label"
            errors={errors}
            touched={touched}
            extra={extra}
            tabIndex={tabIndex}
          />
          <FormField
            name="address"
            required
            caption="Shipping Address"
            placeholder="Enter street address"
            errors={errors}
            touched={touched}
            tabIndex={tabIndex}
          />
          <FormField
            name="city"
            required
            caption="City"
            placeholder="Enter city"
            errors={errors}
            touched={touched}
            tabIndex={tabIndex}
          />
          <State values={values} errors={errors} touched={touched} />
          {/* <FormField
            name="state"
            required
            caption="State"
            placeholder="Enter state"
            errors={errors}
            touched={touched}
            tabIndex={tabIndex}
          /> */}
          <FormField
            name="zipcode"
            required
            caption="Zipcode"
            placeholder="Enter zipcode"
            errors={errors}
            touched={touched}
            tabIndex={tabIndex}
          />
        </div>
      )}
    </>
  );
};

export default ShippingAddress;
