import { useState, useEffect, useRef } from "react";
import AppSettings from "../../AppSettings";

export default function useMicroFlex(apiToken) {
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const numberTouchRef = useRef();
    const securityCodeTouchRef = useRef();
    const formRef = useRef();

    //set up script
    useEffect(() => {
        if (!window.Flex) {
            const script = document.createElement("script");
            script.src = "https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js";
            script.async = true;
            script.onload = () => {
                console.log("loaded microflex script");
            };
            document.body.appendChild(script);
        }

        // Rerun setupMicroform every 15 minutes.  This will clear protected fields if form left open
        const timer = () => setInterval(() => setupMicroform(), 15 * 60 * 1000);
        const timerId = timer();
        console.log("Microflex reset timer STARTED", timerId);
        return () => {
            clearInterval(timerId);
            console.log("Microflex reset timer CLEARED", timerId);
        }
    }, []);

    //set up microform
    useEffect(() => {
        if (!window.Flex) {
            return;
        }
        setupMicroform();
    }, [window.Flex]);

    // This is the actual setup for the microflex fields
    async function setupMicroform() {
        let port = '';
        if (window.location.hostname.includes("localhost"))
            port = window.location.port;
        const apiURL = `${AppSettings.Cybersource}publickey/${port}`;
        console.log("Requesting microflex public key from", apiURL);
        const jwt = await fetch(apiURL, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + apiToken
            }
        })
            .then(resp => resp.text())
            .then(data => data)
            .catch(err => console.log("Caught exception while loading microflex public key", err));

        if (!jwt) {
            console.log("ERROR! Failed to load microflex public key");
            return;
        }

        let styles = {
            'input': {
                'font-size': '16px',
                'font-family': 'helvetica, tahoma, calibri, sans-serif',
                'color': '#555',
                'line-height': '19px'
            },
            ':focus': { 'color': 'blue' },
            ':disabled': { 'cursor': 'not-allowed' },
            'valid': { 'color': '#3C763D' },
            'invalid': { 'color': '#A94442' }
        };

        let flex = new window.Flex(jwt);
        let microform = flex.microform({ styles: styles });
        formRef.current = microform;
        let number = microform.createField('number', { placeholder: 'Card Number' });
        let securityCode = microform.createField('securityCode', { placeholder: 'CVV' });
        //{ placeholder: '•••'}

        // available events: 
        // https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ/Events.html
        number.on('blur', function (data) {
            numberTouchRef.current = true;
            if (securityCodeTouchRef.current) validate();
        });

        securityCode.on('blur', function (data) {
            securityCodeTouchRef.current = true;
            if (numberTouchRef.current) validate();
        });

        number.on("autocomplete", function (data) {
            validate();
        });

        securityCode.on("autocomplete", function (data) {
            validate();
        });

        number.load('#number-container');
        securityCode.load('#securityCode-container');
    }

    // This is the public function to validate the cardnumber and cvv
    function validate() {
        if (formRef.current) {
            console.log("Creating Token");
            formRef.current.createToken({}, function (err, token) {
                if (err && err.message) console.log("ERROR microflex loading transient token", err.message);
                if (token) console.log("Loaded microflex transient token", token);
                setError(err ? err.message : null);
                setToken(token);
            });
        }
    }

    return [validate, token, error]

}